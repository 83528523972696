@font-face {
  font-family: 'myvf-icon';
  src: url('#{$myvf-icons-path}/myvf-icon.ttf?um9g8z') format('truetype'),
  url('#{$myvf-icons-path}/myvf-icon.woff?um9g8z') format('woff'),
  url('#{$myvf-icons-path}/myvf-icon.svg?um9g8z#myvf-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

%myvf-icon {
  font-family: 'myvf-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  position: relative;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.myvf-icon-arrow {
  @extend %myvf-icon;

  &:before {
    content: "\e901";
  }
}

.myvf-icon-document {
  @extend %myvf-icon;

  &:before {
    content: "\e906";
  }
}

.myvf-icon-file {
  @extend %myvf-icon;

  &:before {
    content: "\e900";
  }
}

.myvf-icon-arrow-right {
  @extend %myvf-icon;

  &:before {
    content: "\e902";
  }
}

.myvf-icon-calendar {
  @extend %myvf-icon;

  &:before {
    content: "\e903";
  }
}

.myvf-icon-library {
  @extend %myvf-icon;

  &:before {
    content: "\e921";
  }
}

.myvf-icon-credit-card {
  @extend %myvf-icon;

  &:before {
    content: "\e93f";
  }
}

.myvf-icon-sim {
  @extend %myvf-icon;

  &:before {
    content: "\e904";
  }
}

.myvf-icon-spinner {
  @extend %myvf-icon;

  &:before {
    content: "\e905";
  }
}

.myvf-icon-success {
  @extend %myvf-icon;

  .path1:before {
    content: "\e924";
    font-weight: bold;
  }

  .path2:before {
    content: "\e925";
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
  }
}

.myvf-icon-warning {
  @extend %myvf-icon;

  .path1:before {
    content: "\e92e";
  }

  .path2:before {
    content: "\e92f";
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
  }
}

.myvf-icon-error {
  @extend %myvf-icon;

  .path1:before {
    content: "\e91d";
    font-weight: bold;
  }

  .path2:before {
    content: "\e91e";
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
  }
}

.myvf-icon-locate {
  @extend %myvf-icon;

  &:before {
    content: "\e907";
  }
}
