@font-face {
  font-family: 'vodafone-light';
  src: url('#{$myvf-fonts-path}/vodafone-light.eot');
  src: url('#{$myvf-fonts-path}/vodafone-light.eot?#iefix') format('embedded-opentype'),
  url('#{$myvf-fonts-path}/vodafone-light.woff2') format('woff2'),
  url('#{$myvf-fonts-path}/vodafone-light.woff') format('woff'),
  url('#{$myvf-fonts-path}/vodafone-light.ttf') format('truetype'),
  url('#{$myvf-fonts-path}/vodafone-light.svg#vodafone_ltregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'vodafone-regular';
  src: url('#{$myvf-fonts-path}/vodafone-regular.eot');
  src: url('#{$myvf-fonts-path}/vodafone-regular.eot?#iefix') format('embedded-opentype'),
  url('#{$myvf-fonts-path}/vodafone-regular.woff2') format('woff2'),
  url('#{$myvf-fonts-path}/vodafone-regular.woff') format('woff'),
  url('#{$myvf-fonts-path}/vodafone-regular.ttf') format('truetype'),
  url('#{$myvf-fonts-path}/vodafone-regular.svg#vodafone_rgregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'vodafone-bold';
  src: url('#{$myvf-fonts-path}/vodafone-bold.eot');
  src: url('#{$myvf-fonts-path}/vodafone-bold.eot?#iefix') format('embedded-opentype'),
  url('#{$myvf-fonts-path}/vodafone-bold.woff2') format('woff2'),
  url('#{$myvf-fonts-path}/vodafone-bold.woff') format('woff'),
  url('#{$myvf-fonts-path}/vodafone-bold.ttf') format('truetype'),
  url('#{$myvf-fonts-path}/vodafone-bold.svg#vodafone_rgbold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'vodafone-extrabold';
  src: url('#{$myvf-fonts-path}/vodafone-extrabold.eot');
  src: url('#{$myvf-fonts-path}/vodafone-extrabold.eot?#iefix') format('embedded-opentype'),
  url('#{$myvf-fonts-path}/vodafone-extrabold.woff2') format('woff2'),
  url('#{$myvf-fonts-path}/vodafone-extrabold.woff') format('woff'),
  url('#{$myvf-fonts-path}/vodafone-extrabold.ttf') format('truetype'),
  url('#{$myvf-fonts-path}/vodafone-extrabold.svg#vodafone_exbregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
