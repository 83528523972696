@import 'variables';
@import 'fonts';
@import 'icons';

* {
  box-sizing: border-box;
  outline: none;

  p {
    margin: 0;
  }
}

body {
  font-family: vodafone-regular, sans-serif;
  background-color: #f4f4f4;
  &.iservice {
    background-color: #E5E5E5;
  }
  &.white {
    background-color: #fff;
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

// overwrite ngx-skeleton-loader animation
.skeleton {
  ngx-skeleton-loader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .skeleton-loader {
      background-color: #E2E2E2;
      border-radius: 0;
      height: 20px;
      &:before {
        content: '';
        animation-duration: 1s!important;
        background: linear-gradient(0.25turn, transparent, #fff, transparent), linear-gradient(#eee, #eee), linear-gradient(#eee, #eee)!important;
      }
    }
  }
}
