@import 'environment';
/*media query tablet var*/
$tablet-min-width: 768px;
$tablet-max-width: 1024px;

/*fonts*/
$myvf-font-bold: 'vodafone-bold', sans-serif;
$myvf-font-regular: 'vodafone-regular', sans-serif;
$myvf-font-light: 'vodafone-light', sans-serif;

/*colors*/
$myvf-alluminium: #ebebeb;
$myvf-anthracite: #666666;
$myvf-aqua-blue: #00b0ca;
$myvf-blue: #0096ad;
$myvf-aubergine: #5e2750;
$myvf-background: #f4f4f4;
$myvf-dark-grey: #333333;
$myvf-dark-grey2: #25282B;
$myvf-dark-red: #bd0000;
$myvf-light-grey: #e3e3e3;
$myvf-fresh-orange: #eb9700;
$myvf-gray: #afafaf;
$myvf-lemon-yellow: #fecb00;
$myvf-maroon: #990000;
$myvf-mid-grey: #999999;
$myvf-red: #e60000;
$myvf-red-violet: #9c2aa0;
$myvf-silver: #cccccc;
$myvf-spring-green: #a8b400;
$myvf-turquoise: #007c92;
$myvf-ui-green: #428600;
$myvf-vodafone-grey: #4a4d4e;
$myvf-sunglow: #ffd429;
$myvf-dark-tangerine: #f2a820;
$myvf-rio-grande: #b5bf24;

//not in styleguide but used in zeplin
$myvf-other-gray: #9b9b9b; // TODO sostituire questa variabile nel codice con $myvf-warm-grey
$myvf-warm-grey:#9b9b9b;
